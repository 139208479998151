





























































import { defineComponent, ref } from "@vue/composition-api";
import { RequestResetPasswordFM } from "../models/userForm";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "RequestResetPassword",
  setup() {
    const showMessage = ref(false);
    const form = new RequestResetPasswordFM();
    const composition = useFormModel(form);
    const requestReset = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      showMessage.value = true;
    };

    return {
      // Data
      reqResetPass: composition.instance,
      showMessage,

      // Method
      requestReset,

      // Composition
      ...composition,
    };
  },
});
