














































































import { defineComponent, reactive, Ref, ref } from "@vue/composition-api";
import { Location } from "vue-router";
import Vue from "vue";
import AuthFM from "../models/authForm";
import router from "@/router";
import { setHomeNextUrl } from "@/apps/core/modules/store";
import useFormModel from "@/apps/core/modules/useFormModel";

const useAuthFormModel = (form: AuthFM) => {
  const composition = useFormModel(form);
  const formRef = reactive(form);
  return {
    formRef,
    instance: composition.instance,
    errorMap: composition.errorMap, // reactive

    // Computed
    isValid: form.getIsValid(), // perlu dicek lagi!!!!!

    // Method
    validate: composition.validate,
  };
};

export default defineComponent({
  name: "Login",
  setup() {
    const usernameInput: Ref<any> = ref(null);
    const needLogin = true;
    const form = new AuthFM();
    const composition = useAuthFormModel(form);
    const auth = composition.instance;
    const goToNextURL = () => {
      const routerTo: Location = { name: "home" };
      const query = router.currentRoute.query;
      if (query.next && query.next !== "/home") {
        setHomeNextUrl(query.next as string);
      }
      router.replace(routerTo);
    };
    const login = () => composition.formRef.login(goToNextURL);

    Vue.nextTick().then(() => {
      const divEl: HTMLElement = usernameInput.value.$el;
      const el = divEl.getElementsByTagName("input")[0];
      el.focus();
    });

    return {
      // Data
      auth,
      needLogin,
      usernameInput,

      // Method
      login,

      // Composition
      ...composition,
    };
  },
});
