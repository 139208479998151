import { API } from "@/apps/core/api";
import APP_CONFIG from "@/apps/core/modules/config";
import PlainModel from "@/apps/core/models/plainModel";

class User extends PlainModel {
  oldPassword: string | null = null;
  newPassword: string | null = null;
}

class UserAPI extends API {
  static modelPath = "/anggota/ubah-sandi/";

  constructor() {
    super(UserAPI.modelPath);
    this.updateUrl = `${APP_CONFIG.baseAPIURL}${UserAPI.modelPath}`;
  }
}

export { User, UserAPI };
