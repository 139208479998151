import { snakeCase } from "lodash";
import { Auth, AuthAPI } from "./auth";
import FormModel from "@/apps/core/models/formModel";
import stateRef from "../modules/store";
import { TokenPair } from "./me";

export default class AuthFM extends FormModel {
  constructor() {
    super(new AuthAPI(), new Auth(), ["username", "password"], []);
    // API login mengembalikan pesan error di field detail (bukan non_field_error)
    // TODO: Cek apakah bisa diimplementasikan di formModel saja.
    this.errorMap.detail = "";
  }

  updateErrorMap(errorMap: Record<string, string | Array<string>>) {
    // TODO: Cek apakah bisa diimplementasikan di formModel saja.
    this.validity.validated = false;
    this.validity.edited = false;
    for (const key of Object.keys(this.errorMap)) {
      const respKey = snakeCase(key);
      if (respKey in errorMap) {
        if (Array.isArray(errorMap[respKey])) {
          this.errorMap[key] = (errorMap[respKey] as Array<string>).join(". ");
        } else {
          this.errorMap[key] = errorMap[respKey] as string;
        }
      } else {
        this.errorMap[key] = "";
      }
    }
  }

  setEdited(value: boolean) {
    // TODO: Cek apakah bisa diimplementasikan di formModel saja.
    this.errorMap.detail = "";
    super.setEdited(value);
  }

  async login(onAuthenticated: Function) {
    this.isLoading = true;
    const payload = this.getPayload();
    const respData = await (this.api as AuthAPI).login(payload);
    if ("error" in respData) {
      delete respData.error;
      this.updateErrorMap(respData);
    } else {
      const tokenPair = new TokenPair(respData.refresh, respData.access);
      stateRef.me.setToken(tokenPair);
      // user (fetchMe) akan dihandle oleh router.beforeeach
      onAuthenticated();
    }
    this.isLoading = false;
  }
}
