import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";

class RequestResetPassword extends PlainModel {
  email: string | null = null;
  npm: string | null = null;
}

class RequestResetPasswordAPI extends API {
  static modelPath = "/anggota/request-reset-password/";

  constructor() {
    super(RequestResetPasswordAPI.modelPath);
  }
}

class ResetPassword extends PlainModel {
  newPassword1: string | null = null;
  newPassword2: string | null = null;
}

class ResetPasswordAPI extends API {
  static modelPath = "/anggota/reset-password/";

  constructor() {
    super(ResetPasswordAPI.modelPath);
    // paramsId diambilnya di SFC.
  }
}

export {
  ResetPassword,
  ResetPasswordAPI,
  RequestResetPassword,
  RequestResetPasswordAPI,
};
